import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Käyttäjän sähköpostiosoite ja puhelinnumero.
 * @param user
 * @returns {*|null}
 * @constructor
 */
export default function ContactInfo({ user }) {
    const email = _.get(user, 'email', null);
    const phoneNumber = _.get(user, 'phoneNumber', null);

    const contactInfo = [];
    if (email) contactInfo.push(email);
    if (phoneNumber) contactInfo.push(phoneNumber);

    if (contactInfo.length > 0) {
        return (
            <p>
                {_.map(contactInfo, (item, index) => (
                    <Fragment key={index}>
                        {item}
                        <br />
                    </Fragment>
                ))}
            </p>
        );
    }

    return null;
}

ContactInfo.propTypes = {
    user: PropTypes.object.isRequired,
};
