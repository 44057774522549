import React from 'react';
import PropTypes from 'prop-types';
import Address from './Address';
import ContactInfo from './ContactInfo';
import { locales } from 'shared/constants';
import EmptyState from 'shared/components/EmptyState';
import ChangeHeta from 'shared/UserDetails/containers/commune/ChangeHeta';

/**
 * Renderöi käyttäjän tiedot tiivistetysti.
 * Jos käyttäjä-objekti on tyhjä näytetään surunaama.
 */
export const UserSummary = ({
    user,
    isBeneficiary,
    isSubstituteEmployer,
    isEmployee,
    isCompany,
    isEditMode,
    onError,
    onHetaChange,
    isShowingNameAndSsn
}) => {

    /**
     * Iban
     * @returns {*}
     */
    const renderAccountNumber = () => {
        if (! isEmployee && ! isCompany) {
            return null;
        }

        const accountNumber = user.accountNumber ?? '-';

        return (
            <div>
                {`${_trans('userForm.accountNumber.label')}: `}
                <strong>
                    { accountNumber }
                </strong>
            </div>
        );
    };

    /**
     * Käyttäjän käyttämä kieli.
     * Oletuksena suomi.
     * @returns {*}
     */
    const renderPreferredLanguage = () => {
        const locale = user.locale ?? Object.keys(locales)[0];
        let preferredLocale = '-';

        if (locales.hasOwnProperty(locale)) {
            preferredLocale = _trans(locales[locale]);
        }

        return (
            <div>
                {`${_trans('userForm.locale.label')}: `}
                <strong>
                    { preferredLocale }
                </strong>
            </div>
        );
    };

    /**
     * Näytetäänkö käyttäjän henkilötunnuksen loppuosaa palkkalaskelmilla.
     * @returns {*}
     */
    const renderSsnVisibility = () => {
        const isSsnPostfixVisible = user.exposedAttributes?.hideFullSSN ?? false;

        return (
            <div>
                {`${_trans('userForm.socialSecurityNumber.ssn_postfix_visible_on_payslip_reports.label')}: `}
                <strong className="u-text-capitalize">
                    { _trans(isSsnPostfixVisible ? 'yes' : 'no') }
                </strong>
            </div>
        );
    };

    /**
     * Heta-jäsenyys.
     * @returns {*}
     */
    const renderHetaMemberShip = () => {
        if (! isBeneficiary && ! isSubstituteEmployer) {
            return null;
        }
        const isHetaMember = user.exposedAttributes?.isHeta ?? false;
        const hetaJoinDate = user.attributes.hetaJoinDate ?? false;
        const userId = user.userId ?? -1;
        if (isEditMode && userId > 0) {
            return (
                <ChangeHeta
                    onError={onError}
                    onHetaSave={onHetaChange}
                    isHetaMember={isHetaMember}
                    userId={userId}
                    hetaJoinDate={hetaJoinDate}
                />
            );
        }

        return (
            <div>
                {`${_trans('benefit_decision.form.heta_member.label')}: `}
                <strong className="u-text-capitalize">
                    { _trans(isHetaMember ? 'yes' : 'no') }
                </strong>
            </div>
        );
    };

    /**
     * Yrityskohtaiset tiedot.
     * @returns {*}
     */
    const renderCompanySummary = () => {
        if (! isCompany) {
            return null;
        }

        const isYelInsured = user.exposedAttributes?.isYelInsured ?? false;

        return (
            <div>
                {`${_trans('company.form.yel_insured.label')}: `}
                <strong className="u-text-capitalize">
                    { _trans(isYelInsured ? 'yes' : 'no') }
                </strong>
            </div>
        );
    };

    if (! user || Object.keys(user).length === 0) {
        return (
            <EmptyState icon="sad_face" message={_trans('users.empty_state')} />
        );
    }

    return (
        <div>
            {isShowingNameAndSsn && (
                <p>
                    <strong>{`${user.firstName} ${user.lastName}`}</strong><br/>
                    {user.socialSecurityNumber}<br/>
                </p>
            )}
            <Address user={user} />
            <ContactInfo user={user} />

            {renderAccountNumber()}
            {isShowingNameAndSsn && renderPreferredLanguage()}
            {isShowingNameAndSsn && renderSsnVisibility()}
            {renderHetaMemberShip()}
            {renderCompanySummary()}
        </div>
    );
};

UserSummary.defaultProps = {
    /**
     * Onko kyseessä avustettava.
     */
    isBeneficiary: false,

    /**
     * Onko kyseessä sijaistyönantaja.
     */
    isSubstituteEmployer: false,

    /**
     * Onko kyseessä työntekijä.
     */
    isEmployee: false,

    /**
     * Onko kyseessä yrityshenkilö.
     */
    isCompany: false,

    /**
     * Moodi jossa näkyy Heta-liiton muokkaus nappi
     */
    isEditMode: false,

    /**
     * Kutsutaan kun tapahtuu virhe
     */
    onError: () => {},

    /**
     * Kutsutaan kun tallennus onnistuu
     */
    onHetaChange: () => {},

    /**
     * Näytetäänkö nimi & hetu sekä locale & hide ssn
     * Käytetään sopparilla näyttämään lisätietoa käyttäjästä
     */
    isShowingNameAndSsn: true,
};

UserSummary.propTypes = {
    user: PropTypes.object.isRequired,
    isBeneficiary: PropTypes.bool,
    isSubstituteEmployer: PropTypes.bool,
    isEmployee: PropTypes.bool,
    isCompany: PropTypes.bool,
    isEditMode: PropTypes.bool,
    onError: PropTypes.func,
    onHetaChange: PropTypes.func,
    isShowingNameAndSsn: PropTypes.bool,
};
