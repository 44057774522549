import React from 'react';
import PropTypes from 'prop-types';

/**
 * Käyttäjän katu- ja postiosoite.
 * @param user
 * @returns {*}
 * @constructor
 */
export default function Address({ user }) {
    const streetAddress = _.get(user, 'streetAddress', null);
    const postCode = _.get(user, 'postCode', null);
    const town = _.get(user, 'town', null);

    // Jos ei oo annettu erikseen, vaan yhdessä pötkössä, niin näytetään se
    if (! streetAddress && !postCode && _.has(user, 'fullAddress')) {
        return (
            <p>
                {user.fullAddress}
            </p>
        );
    }

    if (streetAddress && postCode) {
        return (
            <p>
                {streetAddress}<br/>
                {`${postCode} ${town}`}<br/>
            </p>
        );
    }

    return null;
}

Address.propTypes = {
    user: PropTypes.object.isRequired,
};
