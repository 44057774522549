// Redusoidaan oma setti per palkkio/kulukorvaus
import {
    SUB_TYPE_COST_REIMBURSEMENT_CUSTOM,
    SUB_TYPE_COST_REIMBURSEMENT_NORMAL, SUB_TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE,
    TYPE_COST_REIMBURSEMENT,
    TYPE_NORMAL_SALARY
} from 'shared/constants/salaryTypes';

/**
 * DISCLAIMER: Tämä on 99.9% suora kopio v2:sta.
 * @param salaries
 * @param isPresetTableSalaryInUse
 * @param collectiveAgreement
 * @returns {*}
 */
export const createSalaries = (salaries, isPresetTableSalaryInUse, collectiveAgreement = {}) => salaries.reduce((salaries, salaryData) => {
    // Taulukkoliksojen rämppäys joko presetDatalle asettamalla tai per salaari switchaamalla
    const isTableSalaryInUse = salaryData?.isTableSalaryInUse ?? isPresetTableSalaryInUse ?? false;
    const { hasCostReimbursementTableSalaries = false, hasCostReimbursementSpecialAllowanceTableSalaries = false } = collectiveAgreement;

    // Ekana perus palkkio => initialSalary/initialPersonalSalary
    salaries = [...salaries, createSalary(salaryData, TYPE_NORMAL_SALARY, null, isTableSalaryInUse)];

    // Peruskulukorvaus, luodaan samalle createSalary-funkkarille initialSalary alle
    if (isTableSalaryInUse && salaryData.costReimbursement && salaryData.costReimbursement !== '' && hasCostReimbursementTableSalaries) {
        salaries = [...salaries, createSalary(
            {
                ...salaryData,
                initialSalary: salaryData.costReimbursement,
                initialPersonalSalary: 0,
                tableSalaryAttributes: salaryData.costReimbursementAttributes,
                tableSalary: salaryData.costReimbursementMetadataId,
                label: salaryData.costReimbursementLabel,
                isPendingSalary: salaryData.isPendingCostReimbursement,
            },
            TYPE_COST_REIMBURSEMENT,
            SUB_TYPE_COST_REIMBURSEMENT_NORMAL,
            isTableSalaryInUse
        )];

        if (salaryData.costReimbursementSpecialAllowance && salaryData.costReimbursementSpecialAllowance !== '' && hasCostReimbursementSpecialAllowanceTableSalaries) {
            salaries = [...salaries, createSalary(
                {
                    ...salaryData,
                    initialSalary: salaryData.costReimbursementSpecialAllowance,
                    initialPersonalSalary: 0,
                    tableSalaryAttributes: salaryData.costReimbursementSpecialAllowanceAttributes,
                    tableSalary: salaryData.costReimbursementSpecialAllowanceMetadataId,
                    label: salaryData.costReimbursementSpecialAllowanceLabel,
                    isPendingSalary: salaryData.isPendingCostReimbursementSpecialAllowance,
                },
                TYPE_COST_REIMBURSEMENT,
                SUB_TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE,
                isTableSalaryInUse
            )];
        }
    }

    if (salaryData.costReimbursementCustom && salaryData.costReimbursementCustom !== '') {
        // Työvuorotietojen rajat tännekin jos kellonaikapohjaiset kirjaukset käytössä
        const minRequiredWorkTimeDefault = salaryData.minRequiredWorkTimeDefault;
        const minRequiredWorkTimeCostReimbursements = salaryData.minRequiredWorkTimeCostReimbursements;
        const workTimeData = minRequiredWorkTimeDefault && salaryData.minRequiredWorkTimeDefault && salaryData.minRequiredWorkTimeCostReimbursements
            ? { minRequiredWorkTimeDefault, ...(minRequiredWorkTimeCostReimbursements && { minRequiredWorkTimeCostReimbursements })}
            : {};

        salaries = [...salaries, createSalary(
            {
                initialSalary: salaryData.costReimbursementCustom,
                initialPersonalSalary: 0,
                label: _trans('contract.form.salary.cost_reimbursements.cost_reimbursement_custom.label'),
                dimension: salaryData.dimension,
                ...workTimeData,
            },
            TYPE_COST_REIMBURSEMENT,
            SUB_TYPE_COST_REIMBURSEMENT_CUSTOM,
            isTableSalaryInUse
        )];
    }

    return salaries;
}, []);

/**
 * Muodostaa palkan / palkkion tiedot. Poistaa tyhjät keyt.
 * @param salaryData
 * @param type
 * @param subType
 * @param isTableSalaryInUse
 * @returns {any}
 */
const createSalary = (salaryData, type, subType, isTableSalaryInUse) => Object.entries({
    type,
    subType,
    initialSalary: salaryData?.initialSalary?.toString() ?? '',
    isPendingSalary: salaryData?.isPendingSalary ?? false,
    initialPersonalSalary: salaryData?.initialPersonalSalary ?? 0,
    initialPersonalSalaryType: salaryData.initialPersonalSalaryType,
    // Normalisoidaan ja aina null jossei oo valittu tuota prosenttiperusteista
    personalSalaryPercent: salaryData?.initialPersonalSalaryType === 'percent'
        ? salaryData.personalSalaryPercent
        : null,
    partTimePercentage: salaryData?.partTimePercentage ?? null,
    tableSalaryAttributes: isTableSalaryInUse
        ? salaryData.tableSalaryAttributes
        : {},
    tableSalary: isTableSalaryInUse
        ? salaryData.tableSalary
        : null,
    dimension: salaryData.dimension ?? null,
    label: salaryData.label ? salaryData.label : null,
    isHolidayGenerationApplied: salaryData.isHolidayGenerationApplied ?? false,
    /**
     * Nämä käytössä vain jos liksa ei taulukossa & käytössä kellonaikapohjaiset työvuorokirjaukset
     */
    minRequiredWorkTimeDefault: salaryData.minRequiredWorkTimeDefault ?? undefined,
    minRequiredWorkTimeCostReimbursements: salaryData.minRequiredWorkTimeCostReimbursements ?? undefined,
}).reduce((acc, [key, value]) => (
    value !== undefined
        ? Object.assign({}, acc, { [key]: value })
        : acc
), {});
