import superagent from 'superagent';
import SuperagentPromise from 'superagent-promise';
import cacheModule from 'cache-service-cache-module';
import SuperagentCachePlugin from 'superagent-cache-plugin';
import { addLogoutTimer } from 'shared/utils/LogoutDialog';

/**
 * A special version of setup that wraps the end handler with special 403 logic
 */
if (superagent.Request.prototype.originalEnd === undefined) {
    // Only overwrite the end if it's not already overwritten!
    superagent.Request.prototype.originalEnd = superagent.Request.prototype.end;
    superagent.Request.prototype.end = function(fn) {
        addLogoutTimer();
        this.originalEnd(function(error, res) {
            if (error && error.status === 401) {
                // Since this only affects ajax queries the easiest way to force
                // login is to reload the current page which redirects to login
                // properly and keeps the referer in place
                window.location.reload(true);
            } else {
                fn(error, res);
            }
        });
    };
}

// Require superagent-cache-plugin and pass your cache module
const cache = (cacheTTL) => SuperagentCachePlugin(
    new cacheModule({ storage: 'session', defaultExpiration: cacheTTL })
);

const promisedVersion = SuperagentPromise(superagent, Promise);
export {
    /**
     * @deprecated
     */
    superagent as default,
    /**
     * @todo This will become default in the future
     */
    promisedVersion as promised,
    cache
};
