import React from 'react';
import PropTypes from 'prop-types';
import { specialAssign } from './specialAssign';

const checkedProps = {
    children: PropTypes.node.isRequired,
    tag: PropTypes.string,
    role: PropTypes.string,
};

export const TabList = (props) => {
    const { tag, role } = props;
    const elProps = specialAssign({ role }, props, checkedProps);
    return React.createElement(tag, elProps, props.children);
};

TabList.propTypes = checkedProps;

TabList.defaultProps = {
    tag: 'div',
    role: 'tablist',
};
