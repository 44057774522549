import moment from 'moment';
import { createSalaries } from 'ContractV3/utils/salaries/createSalaries';
import { DimensionSchema } from 'ContractV3/schemas/custom/Dimension.schema';
import { contractTypes } from 'shared/constants/contractTypes';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { salaryFieldNames } from 'ContractV3/constants/salaryFieldNames';
import { payDayModes } from 'shared/constants/payDayModes';
import { payPeriodLengths } from 'shared/constants/payPeriodLengths';
import { isAssignmentContractType, types } from 'shared/constants/contract';
import { contractStates } from 'shared/constants/ContractV3/contractStates';


// Sopparille menevät kentät
const contractDataFields = []
    .concat(Object.values(fieldNames))
    .concat(['contractTemplate'])
    .concat(['benefitDecisionId']);

/**
 * Parsitaan normaaliksi dimensions [1234, 4567] -tyyliseksi dimensiolistaksi jota bäkkäri syö.
 * @param dimensions
 * @returns {any}
 */
const transformDimensions = (dimensions) => Object.entries(dimensions)
    .reduce((acc, [id, value]) => {
        const name = `${fieldNames.DIMENSIONS}.${id}`;
        // Kaivetaan matchilla sisältä dimension name ja numeerinen id.
        const namePieces = name.match(DimensionSchema.properties.name.pattern);
        if (namePieces.length !== 3) {
            return acc;
        }
        // Yhdistetään parsinta: dimensions.id212 => dimensions.212
        return [].concat(acc).concat(parseInt(value, 10));
    }, []);

/**
 * Jos toistaiseksi voimassa poistetaan endDate. Muutoin business as usual.
 * @param endDate
 * @param contractType
 * @returns {null|*}
 */
const transformEndDate = (endDate, contractType) => (
    parseInt(contractType, 10) === contractTypes.OPEN_ENDED || endDate === null
        ? null
        : moment(endDate).format('YYYY-MM-DD')
);

// Noukkii vain sopparille menevän datan
const getContractValues = (values) => (
    Object.entries(values).reduce((acc, [name, value]) => {
        if (! contractDataFields.includes(name)) return acc;

        return Object.assign({}, acc, { [name]: value });
    }, {}));

// Loput metadataan
const getMetadataValues = (values) => (
    Object.entries(values)
        .reduce((acc, [name, value]) => {
            if (contractDataFields.includes(name)) return acc;

            return Object.assign({}, acc, { [name]: value });
        }, {}));

/**
 * Riipii salaries-taulukosta pois metadatat ja palauttaa erikseen salaries ja salariesMetadata.
 * @param salaries
 * @returns {*}
 */
const getSalariesMetadata = (salaries) => salaries.reduce((allSalaries, salary) => {
    const { salaries, salariesMetadata } = Object.entries(salary)
        .reduce((allSalaryItems, [key, value]) => {
            const salaryItem = { [key]: value };

            // Jos avain löytyy salaryFieldNamesista viedään salaries-objektiin. Muutoin metadatalle.
            return Object.assign({}, allSalaryItems,
                salaryFieldNames.includes(key)
                    ? { salaries: Object.assign({}, allSalaryItems.salaries, salaryItem) }
                    : { salariesMetadata: Object.assign({}, allSalaryItems.salariesMetadata, salaryItem) });
        }, { salaries: {}, salariesMetadata: {} });

    return Object.assign({}, allSalaries, {
        salaries: allSalaries.salaries.concat(salaries),
        salariesMetadata: allSalaries.salariesMetadata.concat(salariesMetadata)
    });
}, { salaries: [], salariesMetadata: [] });

/**
 * Vääntää datan bäkkärin syömään muotoon.
 * @param values
 * @param presetData
 * @param contract
 * @returns {{salaries: *}}
 */
export const transformContractToBackend = (values, presetData = {}, contract = {}) => {
    const { collectiveAgreement } = contract;
    const { isPresetTableSalaryInUse = false } = presetData;
    const {
        type,
        contractType,
        contractTemplate,
        dataStartDate,
        originalStartDate,
        startDate,
        endDate,
        totalSalary,
        criminalRecordDate,
        fixedTermReason,
        dimensions,
        signingEmployer,
        signingCommuneOperator,
        payDay,
        paydayMode,
        payPeriodLength,

        // Työsopimukseen liittyvät
        benefitDecisionId,
        jobTargetAddress,
        hasTrialPeriod,
        trialPeriodEndDate,
        ...rest
    } = getContractValues(values);
    const { salaries, salariesMetadata } = getSalariesMetadata(values?.salaries ?? []);

    const formattedDataStartDate = moment(dataStartDate).format('YYYY-MM-DD');
    const formattedCriminalRecordDate = moment(criminalRecordDate).format('YYYY-MM-DD');
    const formattedOriginalStartDate = moment(originalStartDate).format('YYYY-MM-DD');
    const formattedStartDate = startDate === null ? null : moment(startDate).format('YYYY-MM-DD');
    const isStartDateSameAsOriginal = formattedOriginalStartDate === formattedStartDate;

    const isNewContract = isNaN(parseInt(rest.jobContract, 10));
    const isAcceptedContract = contract.jobContractState === contractStates.ACCEPTED;
    const canEditDivisor = isNewContract || contract.jobContractState === contractStates.UNFINISHED;

    const getSalaries = () => {
        // OPH: business as usual
        if (isAssignmentContractType(type)) {
            return salaries ? createSalaries(salaries, isPresetTableSalaryInUse, collectiveAgreement) : [];
        }

        // Henkkarilla voidaan päättää ettei halutakaan härvätä palkan kanssa ollenkaan
        return isAcceptedContract && ! contract?.isChangingSalary
            ? []
            : createSalaries(salaries, isPresetTableSalaryInUse, collectiveAgreement);
    };

    const commonValues = {
        ...rest,
        type,
        contractType,
        contractTemplate,
        // Muutosten voimaantulopäivä vain jos ei ole uusi soppari
        dataStartDate: isNewContract ? null : formattedDataStartDate,
        originalStartDate: (! originalStartDate || isStartDateSameAsOriginal) ? null : formattedOriginalStartDate,
        startDate: formattedStartDate,
        endDate: transformEndDate(endDate, contractType),
        criminalRecordDate: criminalRecordDate ? formattedCriminalRecordDate : null,
        fixedTermReason: endDate ? parseInt(fixedTermReason, 10) : null,
        dimensions: dimensions && Object.keys(dimensions).length > 0 ? transformDimensions(dimensions) : {},
        salaries: getSalaries(),
        employerSigner: signingEmployer
            ? parseInt(signingEmployer, 10)
            : null,

        payPeriodLength,
        paydayMode,
        payDay: [
            payDayModes.PAYDAY_USER_CHOICE.toString(),
            payDayModes.PAYDAY_IN_PAY_PERIOD.toString(),
            payDayModes.PAYDAY_NTH_WEEK_DAY.toString(),
        ].includes(paydayMode) && [payPeriodLengths.ONE_MONTH.toString()].includes(payPeriodLength)
            ? payDay
            : null,

        // Jos salaries-taulukosta löytyi metadataa => liitetään osaksi metadataValuesia jotta saadaan ne talteen.
        metadataValues: Object.keys(salariesMetadata).length > 0
            ? Object.assign({}, getMetadataValues(values), { salaries: salariesMetadata })
            : getMetadataValues(values),
    };

    // Vain sopparijuttuset
    if (type === types.JOB_CONTRACT) {
        const formattedTrialPeriodEndDate = moment(trialPeriodEndDate).format('YYYY-MM-DD');
        return Object.assign({}, commonValues, {
            benefitDecision: benefitDecisionId,
            // Koska osoite on string kikkaillaan useampi osoite yhteen näin
            jobTargetAddress: jobTargetAddress.join(';'),
            trialPeriod: hasTrialPeriod,
            trialPeriodEndDate: formattedTrialPeriodEndDate,

            communeOperatorSigner: signingCommuneOperator
                ? parseInt(signingCommuneOperator, 10)
                : null,
        });
    }

    const initialSalaryDivisor = (values[fieldNames.CONTRACT_GROUP_DIVISORS] ?? [])
        .map((divisor) => ({
            userId: divisor.userId,
            divisor: (divisor?.divisor ?? 100) / 100,
            type: 1,
        }));

    const costReimbursementDivisor = (values[fieldNames.CONTRACT_GROUP_DIVISORS] ?? [])
        .filter((divisor) => divisor.hasOwnProperty('costDivisor'))
        .map((divisor) => ({
            userId: divisor.userId,
            divisor: (divisor?.costDivisor ?? 100) / 100,
            type: 2,
        }));

    return Object.assign({}, commonValues, {
        // Palkkiojakajat vain kun soppari on uusi
        ...(canEditDivisor && { [fieldNames.CONTRACT_GROUP_DIVISORS]: initialSalaryDivisor.concat(costReimbursementDivisor ?? [])
        })

    });
};
