/**
 * Piilokentät joita ei tarvitse koskaan renderöidä mutta jotka lomakkeelta on löydyttävä.
 */
export const hiddenFields = {
    COST_REIMBURSEMENT_LABEL: 'costReimbursementLabel',
    COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_LABEL: 'costReimbursementSpecialAllowanceLabel',
    COST_REIMBURSEMENT_METADATA_ID: 'costReimbursementMetadataId',
    COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_METADATA_ID: 'costReimbursementSpecialAllowanceMetadataId',
};

