export const templateTypes = {
    ASSIGNMENT_CONTRACT: 1,
    PERSONAL_ASSISTANCE: 2,
    GENERIC_FORM: 3,
};

export const templateSubTypes = {
    CONTRACT: 1,
    FAMILY_CARER: 2,
    CARED: 3,
    EMPLOYER: 4,
    EMPLOYEE: 5,
};

export const communeTemplateSubTypes = {
    CONTRACT: 1,
    EMPLOYER: 4,
    EMPLOYEE: 5,
};

export const companyTemplateSubTypes = {
    CONTRACT: 1,
    FAMILY_CARER: 2,
    CARED: 3,
};

export const translateTemplateSubType = (type) => {
    switch (parseInt(type, 10)) {
        case templateSubTypes.CONTRACT:
            return 'Sopimukset';
        case templateSubTypes.FAMILY_CARER:
            return 'Hoitaja';
        case templateSubTypes.CARED:
            return 'Hoidettava';
        case templateSubTypes.EMPLOYER:
            return 'Työnantaja';
        case templateSubTypes.EMPLOYEE:
            return 'Työntekijä';
        default:
            return 'Tuntematon';
    }
};
