import React from 'react';
import { LoginForm } from 'Login/LoginForm';

const LoginContainer = () => (
    <div>
        <LoginForm />
    </div>
);

app.render(LoginContainer);
