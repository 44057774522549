import React from 'react';
import PropTypes from 'prop-types';
import createManager from './createManager';
import { specialAssign } from './specialAssign';
import createReactClass from 'create-react-class';

const checkedProps = {
    children: PropTypes.node.isRequired,
    activeTabId: PropTypes.string,
    letterNavigation: PropTypes.bool,
    onChange: PropTypes.func,
    tag: PropTypes.string,
};

export default createReactClass({
    displayName: 'AriaTabPanel-Wrapper',

    propTypes: checkedProps,

    getDefaultProps: function () {
        return { tag: 'div' };
    },

    childContextTypes: {
        atpManager: PropTypes.object.isRequired,
    },

    getChildContext: function () {
        return { atpManager: this.manager };
    },

    componentWillMount: function () {
        this.manager = createManager({
            onChange: this.props.onChange,
            activeTabId: this.props.activeTabId,
            letterNavigation: this.props.letterNavigation,
        });
    },

    componentWillUnmount: function () {
        this.manager.destroy();
    },

    componentDidMount: function () {
        this.manager.activate();
    },

    componentDidUpdate: function (prevProps) {
        var updateActiveTab = (prevProps.activeTabId === this.manager.activeTabId) && (prevProps.activeTabId !== this.props.activeTabId);

        if (updateActiveTab) {
            this.manager.activateTab(this.props.activeTabId);
        }
    },

    render: function () {
        const { tag, children } = this.props;
        const elProps = specialAssign({}, this.props, checkedProps);
        return React.createElement(tag, elProps, children);
    },
});
