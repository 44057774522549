// Assign to `a` all properties in `b` that are not in `reserved`
// or already in `a`
export const specialAssign = (a, b, reserved) => (
    Object.keys(b).reduce((acc, cur) => {
        if (!b.hasOwnProperty(cur) || a[cur] || reserved[cur]) return acc;

        return {
            ...acc,
            [cur]: b[cur],
        };
    }, a)
);
