/**
 * Salarylle pakolliset kentät. Käytetään riipiessä siitä pois metadatat.
 * @type {*[]}
 */
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { hiddenFields } from 'ContractV3/constants/hiddenFields';

export const salaryFieldNames = [
    fieldNames.SALARY_DIMENSION_TYPE,
    fieldNames.IS_TABLE_SALARY_IN_USE,
    fieldNames.TABLE_SALARY,
    fieldNames.TABLE_SALARY_ATTRIBUTES,
    fieldNames.INITIAL_SALARY,
    fieldNames.INITIAL_PERSONAL_SALARY,

    fieldNames.COST_REIMBURSEMENT_ATTRIBUTES,
    fieldNames.COST_REIMBURSEMENT,
    fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_ATTRIBUTES,
    fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE,
    fieldNames.COST_REIMBURSEMENT_CUSTOM,

    fieldNames.IS_PENDING_SALARY,
    fieldNames.IS_PENDING_COST_REIMBURSEMENT,
    fieldNames.IS_PENDING_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE,

    /**
     * Huomaa: nämä käytössä vain jos kellonaikapohjaiset työvuorokirjaukset valittuna (oph)
     */
    fieldNames.MIN_REQUIRED_WORK_TIME_DEFAULT,
    fieldNames.MIN_REQUIRED_WORK_TIME_COST_REIMBURSEMENTS,

    fieldNames.PART_TIME_PERCENTAGE,

    // Nämä eivät vielä missään käytössä lomakkeella. Lisää fieldNamesiin jos tulee käyttöön.
    'initialPersonalSalaryType',
    'personalSalaryPercent',
    'tableSalaryMetadata',
    'tableSalaryMetadataId',
    'initialPartTimePercentageSalary',
    'isHolidayGenerationApplied',
].concat(Object.values(hiddenFields)); // <<< perään piilokentät
