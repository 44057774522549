/**
 * Metadata. Nämä datat kulkevat vain sopparin mukana eivätkä liity syvempään sopparin
 * palkanlaskenta tai muuhun bäkkäripuolen logiikkaan. Tämän saman olisi voinut toteuttaa
 * jobContractDatan attributes-sarakkeella mutta tuli v3:n kanssa out-of-the-box.
 * @type {{IS_EMPLOYEE_NOT_USING_OIMA: string}}
 */
export const metadataNames = {
    IS_EMPLOYEE_NOT_USING_OIMA: 'isEmployeeNotUsingOima',
    IS_RE_SIGNATURE_NEEDED: 'isReSignatureNeeded',
    JOB_DESCRIPTION_TYPE: 'jobDescriptionType',
};
